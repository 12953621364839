import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // this.element.textContent = "Hello World!"
  }

  reset() {
    this.element.reset()
    
    setTimeout(() => {
        let chatArea = document.getElementById('chat-area')
        chatAreaHeight = chatArea.scrollHeight;
        chatArea.scrollTop = chatAreaHeight;
    }, "500");
  }
}
