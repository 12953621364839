import jquery from "jquery"
window.$ = window.jQuery = jquery
import * as bootstrap from "bootstrap"
window.bootstrap = bootstrap

//----------------ツールチップ（Bootstrap）----------------
$(function(){
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  });
});

//----------------リンクをコピーボタン----------------
$(function(){
  $('.copyButton').on('click', function(event){
    var copyTarget = document.getElementById("copyTarget").value;
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(copyTarget);
      alert('リンクがコピーされました。')
    } else {
      alert('リンクがコピーできません。')
    }
  });
});

//----------------ドロップダウンメニュー（Bootstrap）----------------
//枠外クリックで閉じないようにする
$(function(){
  $('.sortDropdown .dropdown-menu').on('click', function(event){
    event.stopPropagation();
  });
});

//----------------記事を読み込む----------------
$(function(){
  var moreNum = 20;
  $('.productList .productList__item:nth-child(n + ' + (moreNum + 1) + ')').addClass('isHidden');
  $('.more').on('click', function() {
    $('.productList .productList__item.isHidden').slice(0, moreNum).removeClass('isHidden');
    if ($('.productList .productList__item.isHidden').length == 0) {
      $('.more').css('display','none');
    }
  });
});

$(function(){
  var moreNum = 10;
  $('.hideList .hideList__item:nth-child(n + ' + (moreNum + 1) + ')').addClass('isHidden');
  $('.more').on('click', function() {
    $('.hideList .hideList__item.isHidden').slice(0, moreNum).removeClass('isHidden');
    if ($('.hideList .hideList__item.isHidden').length == 0) {
      $('.more').css('display','none');
    }
  });
});

//----------------プロダクト詳細 スクロールスパイ----------------
$(window).on('scroll', function (){
  var elem = $('.productDetailList .productDetailList__item');
  var isAnimate = 'isAnimate';
  elem.each(function () {
    var elemOffset = $(this).offset().top;
    var scrollPos = $(window).scrollTop();
    var wh = $(window).height();

    if(scrollPos > elemOffset - wh + (wh / 2) ){
      $(this).addClass(isAnimate);
      $(this).siblings(".productDetailList__item").removeClass(isAnimate);
      var index = $('.productDetailList .productDetailList__item').index(this);
      var index2 = $('.indexList .indexList__item').eq(index);
      $('.indexList .indexList__item').eq(index).addClass(isAnimate);
      $(index2).siblings(".indexList .indexList__item").removeClass(isAnimate);
    }
  });

});

//----------------フォーム 画像選択----------------
$(function(){
  $('input').on('change', function () {
    if ($(this).prop('files')) {
      var file = $(this).prop('files')[0];
      $('.selectImage1').text(file.name);
      $('.selectImage2').text(file.name);
    }
  });
});

//----------------ページ内リンクの調整----------------
$( window ).on("hashchange", function(e) {
  e.preventDefault()
  var href =  location.href
  if (href.includes("scrollspyHeading")) {
    var heading = "#" + href.replace(/(.*)#/,"")
    $("html, body").animate({ 
      scrollTop: ($(heading).offset().top - 200)
    }, 50);
  }
});

$(function() {
  var headerHeight = 200;
  $('[href^="#"]').click(function() {
    var href= $(this).attr("href");
    location.hash = href
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top-headerHeight;
    $("html, body").animate({scrollTop:position}, 50);
    return false;
  });
});

